<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item prop="category_name">
          <el-input v-model="ruleForm.category_name" placeholder="カテゴリー名">
            <template slot="prepend">カテゴリー名</template>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <!--        <el-form-item prop="parent_id">-->
        <!--          <el-input v-model="ruleForm.parent_id" placeholder="親カテゴリー" :disabled="ruleForm.parent_flg">-->
        <!--            <template slot="prepend">親カテゴリー</template>-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <el-form-item prop="parent_id">
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="ruleForm.parent_id"
            :fetch-suggestions="querySearch"
            placeholder="親カテゴリー"
            style="width: 100%"
            @select="handleSelect"
            :disabled="ruleForm.parent_flg"
          >
            <i
              class="el-icon-edit el-input__icon"
              slot="suffix"
              @click="handleIconClick"
            >
            </i>
            <template slot-scope="{ item }">
              <div class="name">{{ (item.value = item.category_name) }}</div>
            </template>
            <template slot="prepend">親カテゴリー</template>
          </el-autocomplete>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item prop="CategoryLevel" label="親FLG：">
          <el-switch
            v-model="ruleForm.parent_flg"
            @change="isParent(ruleForm.parent_flg)"
          ></el-switch>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item prop="sorting" label="区分：">
          <el-radio-group v-model="ruleForm.sorting">
            <el-radio label="商品"></el-radio>
            <el-radio label="サービス"></el-radio>
            <el-radio label="サポート"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form-item>
          <el-button-group>
            <el-button
              type="warning"
              @click="cancelForm()"
              icon="el-icon-document-delete"
              plain
              >閉じる</el-button
            >
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              icon="el-icon-document-checked"
              plain
              >登録・更新</el-button
            >
          </el-button-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import {
  postCategoryData,
  putCategoryData,
  getCategoryDataParent,
  getCategoryDataOne,
} from "api/category";
import { Message } from "element-ui";

export default {
  name: "CategoryForm",
  props: {
    categoryId: {
      type: String, //指定传入的类型
      //type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: "", //这样可以指定默认的值
    },
  },
  data() {
    return {
      ruleForm: {
        category_name: "",
        parent_id: "",
        parent_name: "",
        parent_flg: false,
        sorting: "",
      },
      rules: {
        category_name: [
          {
            required: true,
            message: "カテゴリー名を入力してください",
            trigger: "blur",
          },
        ],
        sorting: [
          { required: true, message: "区分を決めてください", trigger: "blur" },
        ],
      },
      Categories: [],
    };
  },
  created() {
    if (this.categoryId) {
      this.getCategoryDataOne();
    }
    this.getCategoryDataParent();
  },
  methods: {
    postCategoryData() {
      let values = {};
      values["category_name"] = this.ruleForm.category_name;
      values["parent_id"] = this.ruleForm.parent_id;
      values["parent_flg"] = this.ruleForm.parent_flg;
      values["sorting"] = this.ruleForm.sorting;
      postCategoryData(values)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else if (res.data.code == 201) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "warning",
            });
          } else {
            this.$emit("cancel");
            this.$message({
              showClose: true,
              message: "カテゴリー登録・更新失敗しました。",
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    putCategoryData() {
      let values = {};
      values["category_name"] = this.ruleForm.category_name;
      values["parent_id"] = this.ruleForm.parent_id;
      values["parent_flg"] = this.ruleForm.parent_flg;
      values["sorting"] = this.ruleForm.sorting;
      values["id"] = this.categoryId;
      putCategoryData(values)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else if (res.data.code == 201) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "warning",
            });
          } else {
            this.$emit("cancel");
            this.$message({
              showClose: true,
              message: "カテゴリー登録・更新失敗しました。",
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCategoryDataOne() {
      let values = {};
      values["id"] = this.categoryId;
      getCategoryDataOne(values)
        .then((res) => {
          this.ruleForm.category_name = res.data.data[0].category_name;
          this.ruleForm.parent_id = res.data.data[0].parent_id;
          this.ruleForm.parent_flg = res.data.data[0].parent_flg;
          this.ruleForm.sorting = res.data.data[0].sorting;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCategoryDataParent() {
      getCategoryDataParent()
        .then((res) => {
          this.Categories = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.categoryId) {
            this.putCategoryData();
          } else {
            this.postCategoryData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    isParent(parent_flg) {
      if (parent_flg) {
        this.ruleForm.parent_id = "";
      }
    },
    querySearch(queryString, cb) {
      let Categories = this.Categories;
      let results = queryString
        ? Categories.filter(this.createFilter(queryString))
        : Categories;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.category_name
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      // 把选择的顾客名对应的顾客CD一并发送给后端
      console.log(item);
      // this.ruleForm.parent_id = item.id
      // this.ruleForm.parent_name = item.category_name
      // this.ruleForm.Personnel = item.Personnel
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    cancelForm() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
</style>
